import { BaseApi } from '@/services/api/common/baseApi'
import type { User } from '@/models/entities/User'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import autoBind from 'auto-bind'
import type { PortalMessage } from '@/models/messages/PortalMessage'
import { unwrapOptional } from '@/utils/functionalHelpers'

export class CuratorApi extends BaseApi {
	static readonly BASE_URL = BaseApi.BASE_URL + '/admin/curators'
	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = CuratorApi.BASE_URL
		autoBind(this)
	}

	async getCurators(): Promise<User[]> {
		const { value } = (await this.get('/')) as PortalMessage<User[]>
		return unwrapOptional(value)
	}
}
