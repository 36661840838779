<script setup lang="ts">
import { routeInfo } from '@/router/router'
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import type { ComputedRef } from 'vue'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { useUserStore } from '@/stores/userStore'
import { CustomerConnectorApi } from '@/services/api/user/connectorApi'
import { usePromise, useSubscription } from '@/utils/composables'
import { DateTime, Duration } from 'luxon'
import { useRouter } from 'vue-router'
import type { Connector } from '@/models/entities/connector/Connector'
import type { ChartData, ChartOptions } from 'chart.js'
import { BarChart } from 'vue-chart-3'
import type { DataMetric } from '@/models/entities/metrics/DataMetric'
import { roundFixed } from '@/utils/util'

const hasPreviousPage = ref(false)
const hasNextPage = ref(false)
let logsData: any
let incomingNetworkMetrics: ComputedRef<DataMetric[] | undefined>
let outgoingNetworkMetrics: ComputedRef<DataMetric[] | undefined>
const dataUsedTransferInGb = ref(0)
let stopNetworkMetricsSubscription: any
const lastSeen = ref('')

const networkChartData = ref()
const dataUsedPercentage = ref(0)
const auth = inject('auth') as KeycloakClient
const userStore = useUserStore()
const currentPage = ref(1)
const logs = ref()
const networkDataLoaded = ref(false)
const POLLING_INTERVAL = Duration.fromDurationLike({ minutes: 5000 }).toMillis()
const BYTES_PER_MEGABYTE = 10 ** 6
const sovityLight = '#5da9ff'
const numberOfMonthsForNetworkMetrics = ref(6)
const router = useRouter()
const connectorApi = new CustomerConnectorApi(auth)
const userTitle = computed(() => {
	if (userStore.user?.firstName != null && userStore.user?.lastName != null) {
		return userStore.user.firstName + ' ' + userStore.user.lastName
	} else {
		return userStore.user?.username
	}
})

// Fetching the running connectors
const { data: connectors, stopSubscription: stopConnectorSubscription } = useSubscription(connectorApi.getConnectors)

const runningConnectors: any = computed(() => {
	return connectors.value?.filter((c) => c.status === 'RUNNING') ?? []
})

const monthsForNetworkMetrics = computed(() => {
	const now = DateTime.now()
	return listPastMonths(DateTime.local(now.year, now.month, 1), numberOfMonthsForNetworkMetrics.value)
})

const networkChartOptions: ChartOptions<'bar'> = {
	layout: {
		padding: {
			left: 20,
			right: 20,
			top: 0,
			bottom: 0
		}
	},
	datasets: {
		bar: {
			borderColor: sovityLight,
			borderWidth: 2,
			borderRadius: 5,
			backgroundColor: sovityLight + '90'
		}
	},
	animation: {
		duration: 0
	},
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			type: 'category'
		},
		y: {
			beginAtZero: true,
			title: {
				display: true,
				text: 'Transfer Volume (megabytes)'
			}
		}
	}
}

function nextPage() {
	if (currentPage.value < runningConnectors.value.length) {
		currentPage.value += 1
		switchPage()
	}
}

function backPage() {
	if (currentPage.value > 1) {
		currentPage.value -= 1
		switchPage()
	}
}

function switchPage() {
	hasPreviousPage.value = currentPage.value > 1
	hasNextPage.value = currentPage.value !== runningConnectors.value.length
	if (runningConnectors.value.length > 0) {
		getConnectorData(runningConnectors.value[currentPage.value - 1])
	}
}

function getDateString(date: string) {
	return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
}

function calculateDataUsage(incomingData: number, outgoingData: number) {
	dataUsedTransferInGb.value = Math.ceil((incomingData + outgoingData) / 1024)
	dataUsedPercentage.value = 0
}

function getConnectorData(connector: any) {
	/*
	 * Assigning initial network loaded to false in order to
	 * update the network chart data each time the new connector is fetched
	 */
	networkDataLoaded.value = false

	// Getting logs data for specific connector
	logs.value = ''
	logsData = usePromise(connectorApi.getConnectorLogs(connector.uuid)).result
	watch(logsData, async (data: any | any[]) => {
		if (data) {
			logs.value = logsData
			lastSeen.value = getDateString(logsData?.value[logsData?.value?.length - 1]?.time)
		}
	})

	// Getting network metrics for specific connector
	const networkMetrics = useSubscription(loadNetworkMetrics, POLLING_INTERVAL).data
	stopNetworkMetricsSubscription = useSubscription(loadNetworkMetrics, POLLING_INTERVAL).stopSubscription
	outgoingNetworkMetrics = computed(() => {
		return networkMetrics.value?.[1]
	})
	incomingNetworkMetrics = computed(() => {
		return networkMetrics.value?.[0]
	})
	// Loading empty chart if the network chart data of connector is empty
	if (networkDataLoaded.value === false) {
		networkChartData.value = computed<ChartData<'bar'>>(() => {
			const metricsIncoming: DataMetric[] = []
			const titleIncoming = 'Incoming transfer volume over time'
			const metricsOutgoing: DataMetric[] = []
			const titleOutgoing = 'Outgoing transfer volume over time'
			return convertNetworkMetrics(metricsIncoming as DataMetric[], metricsOutgoing as DataMetric[], titleIncoming, titleOutgoing)
		})
	}
	// Calculation of connector's network metrics and data usage
	watch(incomingNetworkMetrics, async (value: any | any[]) => {
		if (value) {
			networkDataLoaded.value = true
			networkChartData.value = computed<ChartData<'bar'>>(() => {
				const metricsIncoming = incomingNetworkMetrics.value ?? []
				const titleIncoming = 'Incoming transfer volume over time'
				const metricsOutgoing = outgoingNetworkMetrics.value ?? []
				const titleOutgoing = 'Outgoing transfer volume over time'
				return convertNetworkMetrics(metricsIncoming as DataMetric[], metricsOutgoing as DataMetric[], titleIncoming, titleOutgoing)
			})
			if (networkChartData.value != null) {
				calculateDataUsage(networkChartData.value.value.datasets[0].data[5], networkChartData.value.value.datasets[1].data[5])
			}
			watch(networkChartData, async (data: any | any[]) => {
				if (data != null) {
					calculateDataUsage(data.value.datasets[0].data[5], data.value.datasets[1].data[5])
				}
			})
		}
	})
}

async function viewLogs(connector: Connector) {
	await router.push(routeInfo.customerConnectorLogs.path(connector.uuid))
}

async function loadNetworkMetrics() {
	const endTime = DateTime.now()
	const startTime = endTime.minus({ months: numberOfMonthsForNetworkMetrics.value }).startOf('month')
	return Promise.all([
		connectorApi.getConnectorIncomingNetworkMetrics(runningConnectors.value[currentPage.value - 1].uuid, startTime, endTime),
		connectorApi.getConnectorOutgoingNetworkMetrics(runningConnectors.value[currentPage.value - 1].uuid, startTime, endTime)
	])
}

function listPastMonths(startingMonth: DateTime, numberOfMonths: number): DateTime[] {
	const monthsList = Array<DateTime>(numberOfMonths)
	monthsList[numberOfMonths - 1] = startingMonth
	for (let remainingMonths = numberOfMonths - 1; remainingMonths > 0; remainingMonths--) {
		const thisMonth = monthsList[remainingMonths]
		monthsList[remainingMonths - 1] = thisMonth.minus({ months: 1 }).startOf('month')
	}
	return monthsList
}

function scaleNetworkMetricsPerMonth(metrics: DataMetric[]): DataMetric[] {
	const monthGroups = monthsForNetworkMetrics.value.reduce<Record<string, DataMetric>>((groups, month) => {
		const key = `${month.monthShort}-${month.year}`
		groups[key] = { time: month, bytesValue: 0 }
		return groups
	}, {})
	metrics.forEach(({ time, bytesValue }) => {
		const key = `${time.monthShort}-${time.year}`
		monthGroups[key].bytesValue += bytesValue
	})
	return Object.values(monthGroups)
}

function convertNetworkMetrics(
	metricsIncoming: DataMetric[],
	metricsOutgoing: DataMetric[],
	titleIncoming: string,
	titleOutgoing: string
): ChartData<'bar'> {
	const perMonthIncomingMetrics = scaleNetworkMetricsPerMonth(metricsIncoming)
	const perMonthOutgoingMetrics = scaleNetworkMetricsPerMonth(metricsOutgoing)

	return {
		labels: perMonthIncomingMetrics.map(({ time }) => {
			return time.toLocaleString({ month: 'short', year: 'numeric' })
		}),
		datasets: [
			{
				label: titleIncoming,
				backgroundColor: sovityLight,

				data: perMonthIncomingMetrics.map(({ bytesValue }) => {
					return roundFixed(bytesValue / BYTES_PER_MEGABYTE, 3)
				})
			},
			{
				label: titleOutgoing,
				backgroundColor: '#002562',
				borderColor: '#002562',
				data: perMonthOutgoingMetrics.map(({ bytesValue }) => {
					return roundFixed(bytesValue / BYTES_PER_MEGABYTE, 3)
				})
			}
		]
	}
}

onMounted(() => {
	// For initial connector fetch
	let initialized = false

	watch(runningConnectors, async (runningConnectorsList: string | any[]) => {
		if (runningConnectorsList && !initialized) {
			switchPage()
			initialized = true
		}
	})
})

onUnmounted(() => {
	if (typeof stopConnectorSubscription === 'function') stopConnectorSubscription()
	if (typeof stopNetworkMetricsSubscription === 'function') stopNetworkMetricsSubscription()
})

userStore.loadUserIfNecessary()
</script>
<template>
	<v-container class="ml-8">
		<v-row>
			<v-sheet class="pa-2">
				<div v-if="auth.isLoggedIn()" class="pt-4 pb-4">
					<h1>Welcome back, {{ userTitle }}!</h1>
				</div>
			</v-sheet>
		</v-row>
		<v-row v-if="connectors == null" class="ma-16 d-flex justify-center">
			<v-progress-circular size="50" indeterminate />
		</v-row>
		<v-row v-else-if="runningConnectors.length === 0" no-gutters>
			<v-sheet class="ml-2 pa-0">
				<div v-if="auth.isLoggedIn()" class="pt-4 text-center">
					<p class="pt-2">You have no running connector.</p>
				</div>
			</v-sheet>
		</v-row>
		<v-row v-else-if="runningConnectors.length > 0" no-gutters>
			<v-col cols="7">
				<v-row>
					<v-sheet>
						<div v-if="auth.isLoggedIn()" class="pt-4 text-center">
							<p v-if="runningConnectors.length === 1" class="pt-2 ml-2">
								You have one running connector.
								<router-link to="/customer/connectors" class="text-decoration-none">
									Click here to manage your connector
								</router-link>
							</p>
							<p v-else-if="runningConnectors.length > 1" class="pt-2 ml-2">
								You have {{ runningConnectors.length }} running connectors.
								<router-link to="/customer/connectors" class="text-decoration-none">
									Click here to manage your connectors
								</router-link>
							</p>
						</div>
					</v-sheet>
				</v-row>
				<v-row>
					<v-col>
						<v-sheet class="v-col-12 mt-6 mb-4" color="secondary">
							<v-row>
								<v-col cols="3">
									<v-btn
										v-if="runningConnectors.length > 1"
										color="primary"
										:disabled="!hasPreviousPage"
										style="text-transform: none"
										variant="text"
										@click="backPage()">
										&#60; &nbsp;Previous
									</v-btn>
								</v-col>
								<v-col cols="6">
									<h3 class="text-center pt-1" style="color: #002562">Connector : {{ runningConnectors[currentPage - 1].name }}</h3>
								</v-col>
								<v-col cols="3">
									<v-btn
										v-if="runningConnectors.length > 1"
										color="primary"
										class="float-right"
										:disabled="!hasNextPage"
										style="text-transform: none"
										variant="text"
										@click="nextPage()"
										>Next&nbsp; &#62;
									</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="7">
						<v-sheet class="pa-6 border mt-1">
							<h5 class="pb-4">Data Usage</h5>
							<div>
								<span class="text-caption pt-2">{{ dataUsedTransferInGb }}</span>
								<v-progress-linear v-model="dataUsedPercentage" color="light-blue" height="25" striped>
									<template #default="{ value }">
										<strong>{{ value }}%</strong>
									</template>
								</v-progress-linear>
							</div>
						</v-sheet>
						<v-sheet class="pa-6 border mt-8" height="315px">
							<h5 class="pb-4">Network Traffic</h5>
							<div v-if="!networkDataLoaded" class="d-flex justify-center">
								<v-progress-circular indeterminate />
							</div>
							<div v-else class="graph-container" style="position: relative; height: 55vh; width: 22vw">
								<bar-chart :chart-data="networkChartData.value" :options="networkChartOptions" :width="850"></bar-chart>
							</div>
						</v-sheet>
					</v-col>
					<v-col cols="5">
						<v-sheet class="pa-2 border ml-1 mt-1 fill-height" style="width: auto; overflow-wrap: break-word">
							<h5 class="ml-2 mt-2 pa-2">Recent Activities</h5>
							<div class="ml-2 mt-2 pb-4 pl-2 pr-4">
								<div v-if="logs == ''" class="d-flex justify-center">
									<v-progress-circular indeterminate />
								</div>
								<div v-for="item in logs.value?.slice(0, 5)" v-else :key="item">
									<p class="text-caption">[{{ item.appName }}]: {{ item.value }}</p>
									<p class="text-caption mb-3">{{ getDateString(item.time) }}</p>
								</div>
							</div>
						</v-sheet>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="4">
				<v-sheet class="pa-7 ml-7 mr-4 border">
					<v-sheet class="pa-4 border">
						<v-container>
							<v-row>
								<v-col>
									<span class="pt-1 text-caption float-end">
										<span class="ma-2">Status </span>
									</span>
								</v-col>
								<v-col>
									<span class="ml-0 pa-1 text-caption float-start" style="background-color: lightgreen">
										{{ runningConnectors[currentPage - 1].status }}
									</span>
								</v-col>
							</v-row>
							<v-row v-if="logs != null">
								<v-col class="text-caption pb-4 text-center"> Last Seen: {{ lastSeen }}</v-col>
							</v-row>
							<v-row cols="auto" justify="center" class="mb-3 mt-3">
								<v-col class="text-center pa-0 pb-4 ma-0" md="10">
									<v-btn class="button" variant="outlined" @click="viewLogs(runningConnectors[currentPage - 1])">
										Connector Logs
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-sheet>
				</v-sheet>
			</v-col>
			<v-col cols="1"></v-col>
		</v-row>
	</v-container>
</template>
<style lang="sass">
.graph-container
  height: auto

.button .v-btn__content
  white-space: normal
  flex: auto
  font-size: 0.75em

.button .v-btn
  min-height: 52px
  height: 100% !important
</style>
