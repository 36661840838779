import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/assets/main.sass'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default createVuetify({
	directives,
	theme: {
		themes: {
			light: {
				colors: {
					primary: '#002562',
					secondary: '#EBF4FF'
				}
			}
		}
	},
	components: {
		components,
		VDataTable
	},
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: { mdi }
	}
})
