import type { PortalMessage } from '@/models/messages/PortalMessage'
import type { User } from '@/models/entities/User'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { unwrapOptional } from '@/utils/functionalHelpers'
import autoBind from 'auto-bind'
import { BaseApi } from '../common/baseApi'

export class UserApi extends BaseApi {
	static readonly BASE_URL = BaseApi.BASE_URL + '/user/users'
	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = UserApi.BASE_URL
		autoBind(this)
	}

	async getUser(): Promise<User> {
		const { value } = (await this.get('/me')) as PortalMessage<User>
		return unwrapOptional(value)
	}
}
