<script setup lang="ts">
import { formatDateTime } from '@/utils/util'
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import { computed } from 'vue'

export type DataField = string | number | boolean | DateTime | object | null | undefined

export interface ObjectDataRow {
	[key: string]: DataField
}

export type DataRow = Array<DataField> | ObjectDataRow

export interface ObjectHeaderSpec {
	header: string
	key: string
}

export type HeaderSpec = string | ObjectHeaderSpec

const props = defineProps({
	headers: {
		type: Array as PropType<HeaderSpec[]>,
		default() {
			return []
		}
	},
	data: {
		type: Array as PropType<DataRow[]>,
		default() {
			return []
		}
	}
})

const formattedHeaders = computed(() => {
	return props.headers.map((headerSpec) => {
		if (typeof headerSpec == 'string') {
			return headerSpec
		} else {
			return headerSpec.header
		}
	})
})

const headerKeys = computed(() => {
	const keys = []
	for (const headerSpec of props.headers) {
		if (typeof headerSpec == 'string') {
			return null
		}
		keys.push(headerSpec.key)
	}
	return keys
})

const formattedData = computed(() => {
	return props.data.map((dataRow) => {
		let row
		if (Array.isArray(dataRow)) {
			row = dataRow
		} else if (headerKeys.value != null) {
			row = headerKeys.value.map((key) => {
				return dataRow[key]
			})
		} else {
			row = Object.values(dataRow)
		}
		return row.map(formatDataField)
	})
})

function formatDataField(dataField: DataField) {
	if (dataField == null) {
		return ''
	} else if (dataField instanceof DateTime) {
		return formatDateTime(dataField)
	} else {
		return dataField.toString()
	}
}
</script>

<template>
	<v-table>
		<thead class="text-left">
			<tr>
				<th v-for="(header, index) in formattedHeaders" :key="index" class="text-h6">{{ header }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(dataRow, rowIndex) in formattedData" :key="rowIndex">
				<td v-for="(dataField, fieldIndex) in dataRow" :key="fieldIndex">{{ dataField }}</td>
			</tr>
		</tbody>
	</v-table>
</template>
