import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { ApplicationError, HttpError, NetworkError } from './errors'
import type { RequestDescription, RequestOptions } from './types'
import autoBind from 'auto-bind'
import type { PortalMessage } from '@/models/messages/PortalMessage'

export class BaseApi {
	protected readonly auth: KeycloakClient
	protected baseUrl: string
	static readonly BASE_URL = '/api' as string

	constructor(auth: KeycloakClient) {
		this.auth = auth
		this.baseUrl = BaseApi.BASE_URL
		autoBind(this)
	}

	private getDescriptionFromRequestOptions<T>({ path, ...rest }: RequestOptions<T>): RequestDescription<T> {
		return { ...rest, url: this.baseUrl + path }
	}

	protected async request<Body = undefined>(config: RequestOptions<Body>): Promise<unknown> {
		// Request URL
		const url = new URL(this.baseUrl + config.path, location.origin)

		// Request Params
		if (config.params != null) {
			for (const key in config.params) {
				url.searchParams.append(key, config.params[key])
			}
		}

		// Request Headers
		const requestOptions: RequestInit = {
			method: config.method,
			headers: { 'Content-Type': 'application/json' }
		}
		if (this.auth.bearerToken != null) {
			requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${this.auth.bearerToken}` }
		}
		if (config.headers != null) {
			requestOptions.headers = { ...requestOptions.headers, ...config.headers }
		}

		// Request Body
		if (config.body != null) {
			requestOptions.body = JSON.stringify(config.body)
		}
		let response = null
		try {
			response = await fetch(url.toString(), requestOptions)
		} catch (error) {
			throw new NetworkError(this.getDescriptionFromRequestOptions(config))
		}
		const responseType = response.headers.get('content-type')
		let responseBody
		if (responseType?.includes('application/json') || responseType?.includes('application/ld+json')) {
			responseBody = await response.json()
		} else {
			responseBody = await response.text()
		}
		if (!response.ok) {
			if (response.status === 401) {
				await this.auth.login()
				return null
			}
			throw new HttpError(this.getDescriptionFromRequestOptions(config), response.status, responseBody)
		}
		if (typeof responseBody == 'object') {
			const portalResponse = responseBody as PortalMessage<unknown>
			if (portalResponse.error != null) {
				throw new ApplicationError(
					this.getDescriptionFromRequestOptions(config),
					portalResponse.error.errorCode,
					portalResponse.error.message,
					portalResponse.error.innerError
				)
			}
		}
		return responseBody
	}

	async post<Body = undefined>(path: string, body?: Body): Promise<unknown> {
		return this.request<Body>({ path, method: 'POST', body })
	}

	async put<Body = undefined>(path: string, body?: Body): Promise<unknown> {
		return this.request<Body>({ path, method: 'PUT', body })
	}

	async patch<Body = undefined>(path: string, body?: Body): Promise<unknown> {
		return this.request<Body>({ path, method: 'PATCH', body })
	}

	async get(path: string, params?: Record<string, string>): Promise<unknown> {
		return this.request({ path, method: 'GET', params })
	}

	async delete(path: string): Promise<unknown> {
		return this.request({ path, method: 'DELETE' })
	}
}
