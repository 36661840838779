import { BaseApi } from '@/services/api/common/baseApi'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import autoBind from 'auto-bind'
import type { PortalMessage } from '@/models/messages/PortalMessage'
import { unwrapOptional } from '@/utils/functionalHelpers'

export class PortalApi extends BaseApi {
	static readonly BASE_URL = BaseApi.BASE_URL + '/admin/portal/settings'
	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = PortalApi.BASE_URL
		autoBind(this)
	}

	async getDeploymentSettings(): Promise<string> {
		const { value } = (await this.get('/deployment')) as PortalMessage<string>
		return JSON.stringify(unwrapOptional(value), undefined, 4)
	}

	async setDeploymentSettings(deploymentSettings: JSON | null): Promise<void> {
		await this.put(`/deployment`, deploymentSettings)
	}
}
