import type { PortalError } from '@/models/messages/PortalMessage'
import autoBind from 'auto-bind'
import { DateTime } from 'luxon'
import type { RequestDescription } from './types'

export class ApiError<R = undefined> extends Error {
	readonly timestamp: DateTime
	readonly attemptedRequest: RequestDescription<R>
	constructor(attemptedRequest: RequestDescription<R>) {
		super()
		this.timestamp = DateTime.now()
		this.name = 'ApiError'
		this.attemptedRequest = attemptedRequest
		autoBind(this)
	}
}

export class NetworkError<R = undefined> extends ApiError<R> {
	constructor(attemptedRequest: RequestDescription<R>) {
		super(attemptedRequest)
		this.name = 'NetworkError'
		autoBind(this)
	}
}

export class HttpError<R = undefined> extends ApiError<R> {
	readonly statusCode: number
	readonly response: unknown
	constructor(attemptedRequest: RequestDescription<R>, statusCode: number, response: unknown) {
		super(attemptedRequest)
		this.statusCode = statusCode
		this.response = response
		this.name = 'HttpError'
		autoBind(this)
	}
}

export class ApplicationError<R = undefined> extends ApiError<R> implements Omit<PortalError, 'errorText'> {
	readonly errorCode: number
	override readonly message: string
	readonly innerError?: { message?: string }
	constructor(attemptedRequest: RequestDescription<R>, errorCode: number, message: string, innerError?: { message?: string }) {
		super(attemptedRequest)
		this.name = 'ApplicationError'
		this.errorCode = errorCode
		this.message = message
		this.innerError = innerError
		autoBind(this)
	}
}
