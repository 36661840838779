<script setup lang="ts">
import { computed, type PropType } from 'vue'
import type { ChartData, ChartOptions } from 'chart.js'
import { LineChart, BarChart } from 'vue-chart-3'
import { DateTime } from 'luxon'
import type { DataMetric } from '@/models/entities/metrics/DataMetric'
import { roundFixed } from '@/utils/util'
import type { CpuMetric } from '@/models/entities/metrics/CpuMetric'

const BYTES_PER_MEGABYTE = 10 ** 6

const props = defineProps({
	cpuMetrics: {
		type: Array as PropType<CpuMetric[]>,
		required: true
	},
	ramMetrics: {
		type: Array as PropType<DataMetric[]>,
		required: true
	},
	incomingNetworkMetrics: {
		type: Array as PropType<DataMetric[]>,
		required: true
	},
	outgoingNetworkMetrics: {
		type: Array as PropType<DataMetric[]>,
		required: true
	},
	numberOfMonthsForNetworkMetrics: {
		type: Number,
		default: 6
	}
})

const monthsForNetworkMetrics = computed(() => {
	const now = DateTime.now()
	return listPastMonths(DateTime.local(now.year, now.month, 1), props.numberOfMonthsForNetworkMetrics)
})

const cpuChartData = computed<ChartData<'line'>>(() => {
	return {
		datasets: [
			{
				label: 'Percent of CPU utilization over time',
				data: props.cpuMetrics.map((metric) => {
					return {
						x: metric.time.toMillis(),
						y: metric.value * 10
					}
				})
			}
		]
	}
})

const ramChartData = computed<ChartData<'line'>>(() => {
	return {
		datasets: [
			{
				label: 'RAM utilization over time',
				data: props.ramMetrics.map(({ time, bytesValue }) => {
					return {
						x: time.toMillis(),
						y: roundFixed(bytesValue / BYTES_PER_MEGABYTE, 3)
					}
				})
			}
		]
	}
})

function listPastMonths(startingMonth: DateTime, numberOfMonths: number): DateTime[] {
	const monthsList = Array<DateTime>(numberOfMonths)
	monthsList[numberOfMonths - 1] = startingMonth
	for (let remainingMonths = numberOfMonths - 1; remainingMonths > 0; remainingMonths--) {
		const thisMonth = monthsList[remainingMonths]
		monthsList[remainingMonths - 1] = thisMonth.minus({ months: 1 }).startOf('month')
	}
	return monthsList
}

function scaleNetworkMetricsPerMonth(metrics: DataMetric[]): DataMetric[] {
	const monthGroups = monthsForNetworkMetrics.value.reduce<Record<string, DataMetric>>((groups, month) => {
		const key = `${month.monthShort}-${month.year}`
		groups[key] = { time: month, bytesValue: 0 }
		return groups
	}, {})
	metrics.forEach(({ time, bytesValue }) => {
		const key = `${time.monthShort}-${time.year}`
		monthGroups[key].bytesValue += bytesValue
	})

	return Object.values(monthGroups)
}

function convertNetworkMetrics(metrics: DataMetric[], title: string): ChartData<'bar'> {
	const perMonthMetrics = scaleNetworkMetricsPerMonth(metrics)
	return {
		labels: perMonthMetrics.map(({ time }) => {
			return time.toLocaleString({ month: 'short', year: 'numeric' })
		}),
		datasets: [
			{
				label: title,
				data: perMonthMetrics.map(({ bytesValue }) => {
					return roundFixed(bytesValue / BYTES_PER_MEGABYTE, 3)
				})
			}
		]
	}
}

const incomingNetworkChartData = computed<ChartData<'bar'>>(() => {
	const metrics = props.incomingNetworkMetrics
	const title = 'Incoming transfer volume over time'
	return convertNetworkMetrics(metrics, title)
})

const outgoingNetworkChartData = computed<ChartData<'bar'>>(() => {
	const metrics = props.outgoingNetworkMetrics
	const title = 'Outgoing transfer volume over time'
	return convertNetworkMetrics(metrics, title)
})

const sovityLight = '#5da9ff'
const cpuChartOptions: ChartOptions<'line'> = {
	datasets: {
		line: {
			borderColor: '#5da9ff',
			backgroundColor: sovityLight + '90',
			borderWidth: 2,
			fill: true,
			pointRadius: 5,
			pointBackgroundColor: '#00000000',
			pointBorderWidth: 0,
			pointHoverBorderColor: '#00000000',
			pointHoverBackgroundColor: sovityLight
		}
	},
	scales: {
		x: { type: 'time', time: { unit: 'minute' } },
		y: {
			type: 'linear',
			beginAtZero: true,
			title: {
				display: true,
				text: 'CPU utilization (%)'
			},
			ticks: {
				display: true
			}
		}
	}
}

const ramChartOptions: ChartOptions<'line'> = {
	...cpuChartOptions,
	scales: {
		...cpuChartOptions.scales,
		y: {
			type: 'linear',
			title: {
				display: true,
				text: 'RAM Utilization (megabytes)'
			}
		}
	}
}

const networkChartOptions: ChartOptions<'bar'> = {
	datasets: {
		bar: {
			borderColor: sovityLight,
			borderWidth: 2,
			borderRadius: 5,
			backgroundColor: sovityLight + '90'
		}
	},
	scales: {
		x: {
			type: 'category'
		},
		y: {
			beginAtZero: true,
			title: {
				display: true,
				text: 'Transfer Volume (megabytes)'
			}
		}
	}
}
</script>

<template>
	<v-container>
		<v-row>
			<v-col>
				<h3>Outgoing Network Traffic</h3>
				<div class="graph-container">
					<bar-chart :chart-data="outgoingNetworkChartData" :options="networkChartOptions"></bar-chart>
				</div>
			</v-col>
			<v-col>
				<h3>Incoming Network Traffic</h3>
				<div class="graph-container">
					<bar-chart :chart-data="incomingNetworkChartData" :options="networkChartOptions"></bar-chart>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<h3>CPU Utilization</h3>
				<div class="graph-container">
					<line-chart :chart-data="cpuChartData" :options="cpuChartOptions"></line-chart>
				</div>
			</v-col>
			<v-col>
				<h3>Memory Utilization</h3>
				<div class="graph-container">
					<line-chart :chart-data="ramChartData" :options="ramChartOptions"></line-chart>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<style lang="sass">
.graph-container
	width: 500px
</style>
