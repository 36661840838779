import type { User } from '@/models/entities/User'
import type { UserApi } from '@/services/api/user/userApi'
import { unwrapOptional } from '@/utils/functionalHelpers'
import { defineStore } from 'pinia'

let _userApi: UserApi | null = null

export function init(userApi: UserApi) {
	_userApi = _userApi ?? userApi
}

export const useUserStore = defineStore('user', {
	state: () => {
		return {
			user: null as User | null
		}
	},
	actions: {
		async loadUser() {
			this.user = await unwrapOptional(_userApi).getUser()
		},
		async loadUserIfNecessary() {
			if (this.user == null) {
				await this.loadUser()
			}
		}
	}
})
