<script setup lang="ts">
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { CustomerConnectorApi } from '@/services/api/user/connectorApi'
import { AppPage } from '@/lib'
import { HttpError, NetworkError } from '@/services/api/common/errors'
import { getEndTime } from '@/models/entities/connector/helpers'
import { routeInfo } from '@/router/router'
import { useSubscription } from '@/utils/composables'
import { formatDateTime } from '@/utils/util'
import { mapOptional } from '@/utils/functionalHelpers'
import { useRouter } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import type { Connector } from '@/models/entities/connector/Connector'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import { useUserStore } from '@/stores/userStore'

const userStore = useUserStore()
userStore.loadUserIfNecessary()
const auth = inject('auth') as KeycloakClient
const connectorApi = new CustomerConnectorApi(auth)
const { data: connectors, err: loadConnectorsErr, stopSubscription } = useSubscription(connectorApi.getConnectors)
const activeConnectors = computed(() => {
	return connectors.value?.filter((c) => c.isActive) ?? []
})
const pageError = computed<unknown>(() => {
	return loadConnectorsErr.value
})
const router = useRouter()

async function viewLogs(connector: Connector) {
	await router.push(routeInfo.customerConnectorLogs.path(connector.uuid))
}

async function viewMetrics(connector: Connector) {
	await router.push(routeInfo.customerConnectorMetrics.path(connector.uuid))
}

const showingError = ref(false)
const errorMessage = ref('')
watch(pageError, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof HttpError) {
		if (newErr.statusCode >= 500) {
			errorMessage.value = 'There was an error on our end'
		} else if (newErr.statusCode === 403) {
			errorMessage.value = `You don't have access to this resource: ${newErr.attemptedRequest.url}`
		}
	} else {
		errorMessage.value = `${newErr}`
	}
})

onUnmounted(stopSubscription)
</script>

<template>
	<app-page
		:title="
			routeInfo.customerConnectors.title(
          userStore.user?.customerOrganizationLegalName ?? userStore.user?.customerOrganizationId)
		">
		<error-popup v-model="showingError" :message="errorMessage"></error-popup>
		<v-container fluid>
			<template v-if="connectors != null">
				<v-row>
					<v-col md="12">
						<h2>General</h2>
						<p>{{ activeConnectors.length }} of {{ connectors.length }} connectors running</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<h2>Connectors</h2>
						<v-row style="height: 70vh">
							<v-col style="overflow: auto">
								<v-table class="text-left" style="width: 2000px">
									<thead>
										<tr>
											<th>Name</th>
											<th>Organization ID</th>
											<th>Connector Endpoint</th>
											<th>Frontend URL</th>
											<th>Status</th>
											<th>Start</th>
											<th>End</th>
											<th>Curator</th>
											<th v-if="auth.hasRole('customer_admin')">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="connector in connectors" :key="connector.uuid">
											<td>
												{{ connector.name }}
											</td>
											<td>
												{{ connector.customerOrganizationId }}
											</td>
											<td>
												{{ connector.connectorEndpointUrl }}
											</td>
											<td>
												<v-btn v-if="connector.status === 'RUNNING'" size="small" :href="connector.frontendUrl" target="_blank">
													View Frontend
												</v-btn>
											</td>
											<td>
												{{ connector.status }}
											</td>
											<td>
												{{ mapOptional(formatDateTime, connector.startedAt) }}
											</td>
											<td>
												{{ mapOptional(formatDateTime, getEndTime(connector)) }}
											</td>
											<td>
												{{ connector.createdBy.email }}
											</td>
											<td v-if="auth.hasRole('customer_admin')">
												<v-btn
													class="mr-1"
													size="x-small"
													color="primary"
													icon="mdi-clipboard-text-search"
													@click="viewLogs(connector)">
												</v-btn>
												<v-btn class="mr-1" size="x-small" color="primary" icon="mdi-gauge" @click="viewMetrics(connector)">
												</v-btn>
											</td>
										</tr>
									</tbody>
								</v-table>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<div v-else class="d-flex justify-center">
				<v-progress-circular indeterminate></v-progress-circular>
			</div>
		</v-container>
	</app-page>
</template>
