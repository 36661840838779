import { BaseApi } from '../common/baseApi'
import * as connectorHelpers from '@/models/entities/connector/helpers'
import type { Connector, RawConnector } from '@/models/entities/connector/Connector'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import autoBind from 'auto-bind'
import type { PortalMessage } from '@/models/messages/PortalMessage'
import { unwrapOptional } from '@/utils/functionalHelpers'
import type { LogEntry, RawLogEntry } from '@/models/entities/log_entry/LogEntry'
import { parseLogEntry } from '@/models/entities/log_entry/helpers'
import type { CpuMetric, RawCpuMetric } from '@/models/entities/metrics/CpuMetric'
import { parseCpuMetric, parseDataMetric } from '@/models/entities/metrics/helpers'
import type { DateTime } from 'luxon'
import type { DataMetric, RawDataMetric } from '@/models/entities/metrics/DataMetric'

export class CustomerConnectorApi extends BaseApi {
	static readonly BASE_URL = BaseApi.BASE_URL + '/user/connectors'

	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = CustomerConnectorApi.BASE_URL
		autoBind(this)
	}

	async getConnectors(): Promise<Connector[]> {
		const { value } = (await this.get('/')) as PortalMessage<RawConnector[]>
		return unwrapOptional(value).map(connectorHelpers.parseConnector)
	}

	async getConnector(connectorId: string): Promise<Connector> {
		const { value } = (await this.get(`/${connectorId}`)) as PortalMessage<RawConnector>
		return connectorHelpers.parseConnector(unwrapOptional(value))
	}

	async getConnectorLogs(connectorId: string): Promise<LogEntry[]> {
		const { value } = (await this.get(`/${connectorId}/logs`)) as PortalMessage<RawLogEntry[]>
		return unwrapOptional(value).map(parseLogEntry)
	}

	async getConnectorCpuMetrics(connectorId: string, startTime: DateTime, endTime: DateTime): Promise<CpuMetric[]> {
		const { value } = (await this.get(`/${connectorId}/metrics/cpu-utilization`, {
			startTime: startTime.toISO(),
			endTime: endTime.toISO()
		})) as PortalMessage<RawCpuMetric[]>
		return unwrapOptional(value).map(parseCpuMetric)
	}

	async getConnectorRamMetrics(connectorId: string, startTime: DateTime, endTime: DateTime): Promise<DataMetric[]> {
		const { value } = (await this.get(`/${connectorId}/metrics/memory-utilization`, {
			startTime: startTime.toISO(),
			endTime: endTime.toISO()
		})) as PortalMessage<RawDataMetric[]>
		return unwrapOptional(value).map(parseDataMetric)
	}

	async getConnectorIncomingNetworkMetrics(connectorId: string, startTime: DateTime, endTime: DateTime): Promise<DataMetric[]> {
		const { value } = (await this.get(`/${connectorId}/metrics/network/incoming`, {
			startTime: startTime.toISO(),
			endTime: endTime.toISO()
		})) as PortalMessage<RawDataMetric[]>
		return unwrapOptional(value).map(parseDataMetric)
	}

	async getConnectorOutgoingNetworkMetrics(connectorId: string, startTime: DateTime, endTime: DateTime): Promise<DataMetric[]> {
		const { value } = (await this.get(`/${connectorId}/metrics/network/outgoing`, {
			startTime: startTime.toISO(),
			endTime: endTime.toISO()
		})) as PortalMessage<RawDataMetric[]>
		return unwrapOptional(value).map(parseDataMetric)
	}
}
