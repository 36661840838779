<script lang="ts" setup>
import type { RouteData } from '../models/RouteData'
import { VBreadcrumbs } from 'vuetify/components'
import { computed } from 'vue'
import type { PropType } from 'vue'
const props = defineProps({
	title: { type: String as PropType<string>, required: true },
	crumbs: {
		type: Array as PropType<RouteData[]>,
		default: () => {
			return [] as RouteData[]
		}
	}
})

const breadcrumbItems = computed(() => {
	return props.crumbs.map((crumb) => {
		return { href: crumb.path, text: crumb.title, disabled: false }
	})
})
</script>

<template>
	<div class="py-1 px-2">
		<h1 class="d-flex px-2">{{ title }}</h1>
		<template v-if="crumbs.length > 1">
			<v-breadcrumbs :items="breadcrumbItems" divider=">"></v-breadcrumbs>
		</template>
		<v-divider></v-divider>
	</div>
	<div class="pa-4">
		<slot></slot>
	</div>
</template>
