export function mapOptional<T, R>(mapper: (x: T) => R, optionalX: T | null | undefined): R | null | undefined {
	// Had to write extra boilerplate code to keep Typescript happy
	if (optionalX === null) {
		return null
	} else if (optionalX === undefined) {
		return undefined
	} else {
		return mapper(optionalX)
	}
}

export function unwrapOptional<T>(optionalX: T | null | undefined): T {
	if (optionalX != null) {
		return optionalX
	} else {
		throw new Error('Value expected!')
	}
}

export function unwrapOptionalWithDefault<T>(optionalX: T | null | undefined, fallback: T): T {
	if (optionalX != null) {
		return optionalX
	} else {
		return fallback
	}
}
