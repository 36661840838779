import autoBind from 'auto-bind'
import type { KeycloakClient } from '../keycloak/keycloak'
import { BaseApi } from './common/baseApi'

export class HealthcheckApi extends BaseApi {
	static BASE_URL = BaseApi.BASE_URL
	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = HealthcheckApi.BASE_URL
		autoBind(this)
	}

	async checkHealth(): Promise<void> {
		await this.get('/actuator/health')
	}
}
