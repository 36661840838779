<script setup lang="ts">
import { usePromise, useSubscription } from '@/utils/composables'
import { routeInfo } from '@/router/router'
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { AppPage } from '@/lib'
import { useRoute } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import { ApplicationError, NetworkError } from '@/services/api/common/errors'
import { useUserStore } from '@/stores/userStore'
import { DateTime, Duration } from 'luxon'
import ConnectorMetricsViewer from '@/views/components/ConnectorMetricsViewer.vue'
import { SovityConnectorApi } from '@/services/api/admin/connectorApi'
import ConnectorInfoPanel from '@/views/components/ConnectorInfoPanel.vue'

const auth = inject('auth') as KeycloakClient
const connectorApi = new SovityConnectorApi(auth)
const props = defineProps({
	connectorId: {
		type: String,
		required: true
	}
})
const userStore = useUserStore()
userStore.loadUserIfNecessary()

const route = useRoute()
const POLLING_INTERVAL = Duration.fromDurationLike({ minutes: 1 }).toMillis()
const numberOfMonthsForNetworkMetrics = ref(6)
const {
	data: cpuMetrics,
	err: cpuMetricsErr,
	stopSubscription: stopCpuMetricsSubscription
} = useSubscription(loadCpuMetrics, POLLING_INTERVAL)
const {
	data: ramMetrics,
	err: ramMetricsErr,
	stopSubscription: stopRamSubscription
} = useSubscription(loadRamMetrics, POLLING_INTERVAL)
const {
	data: networkMetrics,
	err: networkMetricsErr,
	stopSubscription: stopNetworkMetricsSubscription
} = useSubscription(loadNetworkMetrics, POLLING_INTERVAL)
const { result: connector, err: connectorLoadErr } = usePromise(connectorApi.getConnector(props.connectorId))
const incomingNetworkMetrics = computed(() => {
	return networkMetrics.value?.[0]
})
const outgoingNetworkMetrics = computed(() => {
	return networkMetrics.value?.[1]
})
const pageErr = computed(() => {
	return cpuMetricsErr.value ?? ramMetricsErr.value ?? networkMetricsErr.value ?? connectorLoadErr.value ?? null
})

const title = computed(() => {
	return routeInfo.customerConnectorMetrics.title(connector.value?.name)
})

const crumbs = computed(() => {
	return [
		{
			...routeInfo.adminManagementConsole
		},
		{ title: title.value, path: route.path }
	]
})

function loadCpuMetrics() {
	const endTime = DateTime.now()
	const startTime = endTime.minus({ hour: 1 })
	return connectorApi.getConnectorCpuMetrics(props.connectorId, startTime, endTime)
}

function loadRamMetrics() {
	const endTime = DateTime.now()
	const startTime = endTime.minus({ hour: 1 })
	return connectorApi.getConnectorRamMetrics(props.connectorId, startTime, endTime)
}

async function loadNetworkMetrics() {
	const endTime = DateTime.now()
	const startTime = endTime.minus({ months: numberOfMonthsForNetworkMetrics.value }).startOf('month')
	return Promise.all([
		connectorApi.getConnectorIncomingNetworkMetrics(props.connectorId, startTime, endTime),
		connectorApi.getConnectorOutgoingNetworkMetrics(props.connectorId, startTime, endTime)
	])
}

const showingError = ref(false)
const errorMessage = ref('')
watch(pageErr, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof ApplicationError) {
		errorMessage.value = `${newErr.message}`
	} else {
		errorMessage.value = `${newErr}`
	}
})

onUnmounted(() => {
	stopCpuMetricsSubscription()
	stopRamSubscription()
	stopNetworkMetricsSubscription()
})
</script>

<template>
	<app-page :title="title" :crumbs="crumbs">
		<div>
			<error-popup v-model="showingError" :message="errorMessage"></error-popup>
			<div
				v-if="
					connector == null ||
					cpuMetrics == null ||
					ramMetrics == null ||
					incomingNetworkMetrics == null ||
					outgoingNetworkMetrics == null
				"
				class="d-flex justify-center">
				<v-progress-circular indeterminate></v-progress-circular>
			</div>
			<template v-else>
				<connector-info-panel :connector="connector" :auth="auth"></connector-info-panel>
				<connector-metrics-viewer
					:cpu-metrics="cpuMetrics"
					:ram-metrics="ramMetrics"
					:incoming-network-metrics="incomingNetworkMetrics"
					:outgoing-network-metrics="outgoingNetworkMetrics"></connector-metrics-viewer>
			</template>
		</div>
	</app-page>
</template>

<style lang="sass">
.graph-container
	width: 500px
</style>
