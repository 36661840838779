// eslint-disable-next-line
// @ts-ignore
import vuetify from '@/plugins/vuetify.js'
import '@/plugins/chartjs.js'
import { buildRouter } from '@/router/router'
import { KeycloakClient } from '@/services/keycloak/keycloak'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import { useSubscription } from './utils/composables'
import { HealthcheckApi } from './services/api/healthcheck'
import { init as initUserStore } from './stores/userStore'
import { UserApi } from './services/api/user/userApi'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

async function main() {
	const auth = new KeycloakClient()
	await auth.init()
	const router = buildRouter(auth)

	const healthcheckApi = new HealthcheckApi(auth)
	useSubscription(() => {
		return Promise.all([auth.refreshTokenIfAboutToExpire(), healthcheckApi.checkHealth()])
	}, 5_000)

	initUserStore(new UserApi(auth))

	const app = createApp(App)
	app.provide('auth', auth)
	app.use(createPinia())
	app.use(vuetify)
	app.use(router)
	app.component('EasyDataTable', Vue3EasyDataTable)
	app.mount('#app')
}

void main()
