<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { VApp, VAppBar, VImg, VList, VMain, VNavigationDrawer, VRow, VSpacer } from 'vuetify/components'
import sovityLogoDark from '../assets/sovity_logo_dark.svg'
import sovityLogoLight from '../assets/sovity_logo_light.svg'

const props = defineProps({
	initialTheme: {
		type: String as PropType<'light' | 'dark'>,
		default: 'light'
	},
	useOsTheme: {
		type: Boolean,
		default: false
	}
})

const initialThemeQuery = window.matchMedia('(prefers-color-scheme: dark)')
initialThemeQuery.addEventListener('change', trackOsTheme)
const osTheme = ref<'dark' | 'light'>(initialThemeQuery.matches ? 'dark' : 'light')

function trackOsTheme(event: MediaQueryListEvent) {
	osTheme.value = event.matches ? 'dark' : 'light'
}

const theme = computed(() => {
	if (props.useOsTheme) {
		return osTheme.value
	} else {
		return props.initialTheme
	}
})
const sovityLogo = computed(() => {
	return theme.value === 'dark' ? sovityLogoDark : sovityLogoLight
})
</script>

<template>
	<v-app :theme="theme">
		<v-app-bar flat outlined app class="border-b">
			<template #prepend>
				<v-img alt="sovity logo" contain :width="140" :src="sovityLogo"></v-img>
			</template>
			<v-spacer></v-spacer>
			<slot name="top-left"></slot>
		</v-app-bar>
		<v-navigation-drawer permanent>
			<v-list nav>
				<slot name="nav-items"></slot>
			</v-list>
			<template #append>
				<v-row no-gutters>
					<v-sheet class="pa-4 ma-0 text-center text-caption" style="height: auto; width: auto" color="secondary">
						<h4>Do you have feedback or need help?</h4>
						<p><em>Select &nbsp;<kbd>SAML</kbd>&nbsp; login on following page</em></p>
						<v-btn
							rounded="lg"
							color="primary"
							class="mt-4"
							size="small"
							href="https://sovity.zammad.com/#customer_ticket_new"
							target="_blank">
							Create Ticket
						</v-btn>
						<v-btn
							rounded="lg"
							color="primary"
							class="mt-4"
							size="small"
							href="https://sovity.zammad.com/#knowledge_base"
							target="_blank">
							Tutorial & FAQ
						</v-btn>
					</v-sheet>
				</v-row>

				<div class="pa-4 text-center text-light-blue-darken-4 text-caption" style="height: 55px; width: auto">
					<v-icon icon="mdi-copyright" />
					{{ new Date().getFullYear() }} — sovity GmbH
				</div>
			</template>
		</v-navigation-drawer>
		<v-main app>
			<slot></slot>
		</v-main>
	</v-app>
</template>
