import { parseDate } from '@/utils/parsers'
import type { CpuMetric, RawCpuMetric } from './CpuMetric'
import type { DataMetric, RawDataMetric } from './DataMetric'

export function parseCpuMetric(cpuMetric: RawCpuMetric): CpuMetric {
	return { ...cpuMetric, time: parseDate(cpuMetric.time) }
}

export function parseDataMetric(dataMetric: RawDataMetric): DataMetric {
	return { ...dataMetric, time: parseDate(dataMetric.time) }
}
