<script setup lang="ts">
import { AppPage } from '@/lib'
import { routeInfo } from '@/router/router'
import { computed, inject, isRef, ref, watch } from 'vue'
import { minLength, required } from '@vuelidate/validators'
import { type ErrorObject, useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { HttpError, NetworkError } from '@/services/api/common/errors'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import SuccessPopup from '@/views/components/SuccessPopup.vue'
import { PortalApi } from '@/services/api/admin/portalApi'
import { usePromise } from '@/utils/composables'

const auth = inject('auth') as KeycloakClient
const portalApi = new PortalApi(auth)

const router = useRouter()

const { result: deploymentSettings } = usePromise(portalApi.getDeploymentSettings())
const crumbs = [routeInfo.adminManagementConsole, routeInfo.adminNewConnector]

const submissionError = ref<unknown>(null)
const pageError = computed(() => {
	return deploymentSettings
})

function mapVuelidateErrors({ $errors }: { $errors: ErrorObject[] }) {
	return $errors.map(({ $message }: ErrorObject) => {
		return isRef($message) ? $message.value : $message
	})
}

const rules = {
	deploymentSettings: { required, minLength: minLength(6), $autoDirty: true }
}

const vuelidate = useVuelidate(
	rules,
	{
		deploymentSettings
	},
	{ $lazy: true }
)

async function validate(): Promise<boolean> {
	if (await vuelidate.value.$validate()) {
		try {
			JSON.parse(deploymentSettings.value || '')
			return true
		} catch (e) {
			return false
		}
	} else {
		return false
	}
}

async function submit() {
	try {
		if (await validate()) {
			await portalApi.setDeploymentSettings(JSON.parse(deploymentSettings.value || ''))
			await router.push(routeInfo.adminSettings.path)
			successMessage.value = 'Successfully updated'
			showingSuccessPopup.value = true
		}
	} catch (err) {
		submissionError.value = err
	}
}

const showingError = ref(false)
const showingSuccessPopup = ref(false)
const errorMessage = ref('')
const successMessage = ref('')

watch(pageError, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof HttpError) {
		if (newErr.statusCode >= 500) {
			errorMessage.value = 'There was an error on our end'
		} else if (newErr.statusCode === 403) {
			errorMessage.value = `You don't have access to this resource: ${newErr.attemptedRequest.url}`
		}
	} else {
		errorMessage.value = `${newErr}`
	}
})
</script>

<template>
	<app-page :title="routeInfo.adminSettings.title" :crumbs="crumbs">
		<error-popup v-model="showingError" :message="errorMessage"></error-popup>
		<success-popup v-model="showingSuccessPopup" :message="successMessage"></success-popup>
		<v-form>
			<v-container class="pa-0">
				<v-row>
					<v-col cols="12" md="12">
						<v-textarea
							id="json-textarea"
							v-model="deploymentSettings"
							label="Deployment Settings"
							rows="40"
							:error-messages="mapVuelidateErrors(vuelidate.deploymentSettings)"></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4">
						<v-btn class="mr-4" color="primary" @click="submit()">Save</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</app-page>
</template>
<style>
#json-textarea {
	line-height: 0.9rem;
	font-size: 0.8rem;
	font-family: monospace;
	font-weight: lighter;
}
</style>
