<script setup lang="ts">
import { computed, type PropType } from 'vue'
import type { Connector } from '@/models/entities/connector/Connector'
import { getEndTime } from '@/models/entities/connector/helpers'
import { formatDateTime } from '@/utils/util'

const props = defineProps({
	connector: {
		type: Object as PropType<Connector>,
		required: true
	}
})
const endTime = computed(() => {
	return getEndTime(props.connector)
})
</script>
<template>
	<v-expansion-panels class="mb-5" variant="accordion">
		<v-expansion-panel>
			<v-expansion-panel-title>
				<v-row>
					<v-col class="d-flex justify-left" cols="3">Name: {{ connector.name }}</v-col>
					<v-col cols="6">Curator: {{ connector.createdBy.username }}</v-col>
					<v-col cols="3">Status: {{ connector.status }}</v-col>
				</v-row>
			</v-expansion-panel-title>
			<v-expansion-panel-text>
				<v-table compact>
					<tbody>
						<tr>
							<th>Name</th>
							<td>{{ connector.name }}</td>
						</tr>
						<tr v-if="connector.description != ''">
							<th>Description</th>
							<td>{{ connector.description }}</td>
						</tr>
						<tr v-if="connector.connectorEndpointUrl != null">
							<th>Frontend URL</th>
							<td>
								<a :href="connector.frontendUrl">{{ connector.frontendUrl }}</a>
							</td>
						</tr>
						<tr v-if="connector.connectorEndpointUrl != null">
							<th>Connector Endpoint</th>
							<td>
								{{ connector.connectorEndpointUrl }}
							</td>
						</tr>
						<tr v-if="connector.managementApiUrl != null">
							<th>Management API Endpoint</th>
							<td>
								<a :href="connector.managementApiUrl">{{ connector.managementApiUrl }}</a>
							</td>
						</tr>
						<tr>
							<th>Status</th>
							<td>{{ connector.status }}</td>
						</tr>
						<tr v-if="connector.startedAt != null">
							<th>Start Time</th>
							<td>{{ formatDateTime(connector.startedAt) }}</td>
						</tr>
						<tr v-if="connector.isActive">
							<th>Planned End Time</th>
							<td>
								{{
									connector.plannedEndAt == null
										? 'Displayed when the connector is running'
										: getEndTime(connector)
								}}
							</td>
						</tr>
						<tr v-else-if="endTime != null">
							<th>End Time</th>
							<td>{{ formatDateTime(endTime) }}</td>
						</tr>
						<tr>
							<th>Created by</th>
							<td>
								<a :href="connector.createdBy.email">{{ connector.createdBy.email }}</a>
							</td>
						</tr>
						<tr>
							<th>Created by URI</th>
							<td>
								<a :href="connector.createdByUri">{{ connector.createdByUri }}</a>
							</td>
						</tr>
						<tr v-for="image in connector.deploymentEntitySet" v-show="connector.deploymentEntitySet != null" :key="image">
							<th>Image {{ image.repositoryImageType }}</th>
							<td>
								{{ image.imageString }} <br />
								Environment value - {{ image.environmentVariables }}
							</td>
						</tr>
						<tr>
							<th>Connector IAM</th>
							<td>{{ connector.name }} ({{ connector.connectorType }})</td>
						</tr>
						<tr>
							<th>Connector IAM URL</th>
							<td>
								<a :href="connector.dapsJwksUrl">{{ connector.connectorEndpointUrl }}</a>
							</td>
						</tr>
					</tbody>
				</v-table>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
