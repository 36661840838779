import {
	Chart,
	LineController,
	BarController,
	TimeScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	CategoryScale,
	Tooltip,
	Legend,
	Filler
} from 'chart.js'
import 'chartjs-adapter-luxon'

Chart.register(
	LineController,
	BarController,
	LinearScale,
	TimeScale,
	CategoryScale,
	PointElement,
	LineElement,
	BarElement,
	Tooltip,
	Legend,
	Filler
)
