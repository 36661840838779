import type { Connector, RawConnector } from './Connector'
import { mapOptional } from '@/utils/functionalHelpers'
import { parseDate, parseDuration } from '@/utils/parsers'
import type { DateTime } from 'luxon'
import { formatDateTime } from '@/utils/util'

export function getEndTime(connector: Connector): DateTime | string | null | undefined {
	if (connector.isActive) {
		if (connector.plannedEndAt !== undefined && connector.startedAt !== undefined) {
			return connector.plannedEndAt < connector.startedAt ? '∞' : formatDateTime(connector.plannedEndAt)
		}
	} else {
		return formatDateTime(connector.endedAt) ?? null
	}
}

export function parseConnector(connector: RawConnector): Connector {
	return {
		...connector,
		creationDate: parseDate(connector.creationDate),
		modificationDate: parseDate(connector.modificationDate),
		inStateSince: parseDate(connector.inStateSince),
		startedAt: mapOptional(parseDate, connector.startedAt) as DateTime | undefined,
		endedAt: mapOptional(parseDate, connector.endedAt) as DateTime | undefined,
		plannedRuntime: parseDuration(connector.plannedRuntime),
		plannedEndAt: mapOptional(parseDate, connector.plannedEndAt) as DateTime | undefined
	}
}
