<script lang="ts" setup>
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { AppPage } from '@/lib'
import { HttpError, NetworkError } from '@/services/api/common/errors'
import { useSubscription } from '@/utils/composables'
import { formatDateTime } from '@/utils/util'
import { mapOptional } from '@/utils/functionalHelpers'
import { routeInfo } from '@/router/router'
import { useRouter } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { SovityConnectorApi } from '@/services/api/admin/connectorApi'
import type { Connector } from '@/models/entities/connector/Connector'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import { useUserStore } from '@/stores/userStore'
import { getEndTime } from '@/models/entities/connector/helpers'

const auth = inject('auth') as KeycloakClient
const connectorApi = new SovityConnectorApi(auth)
const { data: connectors, err: fetchConnectorsError, stopSubscription, fetchNow } = useSubscription(connectorApi.getConnectors)
const selectedFilterEntries = ref([
	'PROVISIONING',
	'INIT',
	'AWAITING_RUNNING',
	'RUNNING',
	'DEPROVISIONING',
	'AWAITING_STOPPED'
])
const itemsPerPage = ref(50)
const search = ref(' ')
const headers = ref([
	{ title: 'Name', align: 'start', key: 'name' },
	{ title: 'Organization', align: 'start', key: 'customerOrganizationLegalName' },
	{ title: 'Organization ID', align: 'start', key: 'customerOrganizationId' },
	{ title: 'Access', align: 'start', key: 'access' },
	{ title: 'Status', align: 'start', key: 'status' },
	{ title: 'Type', align: 'start', key: 'connectorType' },
	{ title: 'Start', align: 'start', key: 'startedAt' },
	{ title: 'End', align: 'start', key: 'endedAt' },
	{ title: 'Curator', align: 'start', key: 'createdBy.email' },
	{ title: 'Actions', align: 'start', key: 'actions' }
])

function filterList(value: string, query: string, item: any) {
	if (!item) return false
	const c = item.raw
	if (selectedFilterEntries.value.includes('ELEMENTS')) {
		return (
			userStore.user?.email === c.createdBy.email &&
			(selectedFilterEntries.value.length === 1 || selectedFilterEntries.value.includes(c.status))
		)
	} else {
		return selectedFilterEntries.value.includes(c.status)
	}
}

const userStore = useUserStore()
userStore.loadUserIfNecessary()
const pageError = ref<unknown>(null)
watch(fetchConnectorsError, (newErr) => {
	if (newErr != null) {
		pageError.value = newErr
	}
})
const activeConnectors = computed(() => {
	return connectors.value?.filter((c) => c.isActive) ?? []
})
const router = useRouter()

async function createConnector() {
	await router.push(routeInfo.adminNewConnector.path)
}

async function viewLogs(connector: Connector) {
	await router.push(routeInfo.adminConnectorLogs.path(connector.uuid))
}

async function viewPortalLogs(connector: Connector) {
	await router.push(routeInfo.adminConnectorPortalLogs.path(connector.uuid))
}

async function viewMetrics(connector: Connector) {
	await router.push(routeInfo.adminConnectorMetrics.path(connector.uuid))
}

async function startConnector(connector: Connector) {
	try {
		await connectorApi.startConnector(connector.uuid)
		fetchNow()
	} catch (error) {
		pageError.value = error
	}
}

async function stopConnector(connector: Connector) {
	try {
		await connectorApi.stopConnector(connector.uuid)
		fetchNow()
	} catch (error) {
		pageError.value = error
	}
}

async function deleteConnector(connector: Connector) {
	try {
		await connectorApi.deleteConnector(connector.uuid)
		fetchNow()
	} catch (error) {
		pageError.value = error
	}
}

const showingError = ref(false)
const errorMessage = ref('')
watch(pageError, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof HttpError) {
		if (newErr.statusCode >= 500) {
			errorMessage.value = 'There was an error on our end'
		} else if (newErr.statusCode === 403) {
			errorMessage.value = `You don't have access to this resource: ${newErr.attemptedRequest.url}`
		}
	} else {
		errorMessage.value = `${newErr}`
	}
})
onUnmounted(stopSubscription)
</script>

<template>
	<app-page :title="routeInfo.adminManagementConsole.title">
		<error-popup v-model="showingError" :message="errorMessage"></error-popup>
		<v-container class="ma-0">
			<template v-if="connectors != null">
				<v-row>
					<v-col md="12">
						<p>{{ activeConnectors.length }} of {{ connectors.length }} connectors running</p>
						<v-btn @click="createConnector()"> Create new connector</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="12">
						<h2>Connectors</h2>
						<v-container>
							<v-row no-gutters>
								<v-col cols="1" class="d-inline-flex pa-2 mt-3 pr-8">
									<v-sheet> Filter: </v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox
											v-model="selectedFilterEntries"
											value="ELEMENTS"
											:label="`User: ` + userStore.user?.email"
											hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox v-model="selectedFilterEntries" value="INIT" :label="`INIT`" hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2">
									<v-sheet>
										<v-checkbox
											v-model="selectedFilterEntries"
											value="PROVISIONING"
											:label="`PROVISIONING`"
											hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox
											v-model="selectedFilterEntries"
											value="AWAITING_RUNNING"
											:label="`AWAITING RUNNING`"
											hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox v-model="selectedFilterEntries" value="RUNNING" :label="`RUNNING`" hide-details></v-checkbox>
									</v-sheet>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="1">
									<v-sheet class="mt-5 pr-8 d-inline-flex pa-2"> </v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3 ml-3">
									<v-sheet>
										<v-checkbox
											v-model="selectedFilterEntries"
											value="DEPROVISIONING"
											:label="`DEPROVISIONING`"
											hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox
											v-model="selectedFilterEntries"
											value="AWAITING_STOPPED"
											:label="`AWAITING STOPPED`"
											hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox v-model="selectedFilterEntries" value="STOPPED" :label="`STOPPED`" hide-details></v-checkbox>
									</v-sheet>
								</v-col>
								<v-col cols="auto" class="d-inline-flex pa-2 mr-3">
									<v-sheet>
										<v-checkbox v-model="selectedFilterEntries" value="ERROR" :label="`ERROR`" hide-details></v-checkbox>
									</v-sheet>
								</v-col>
							</v-row>
						</v-container>
						<v-text-field
							v-model="search"
							label="Search"
							v-if="false"
						></v-text-field>
						<v-data-table
							v-model:items-per-page="itemsPerPage"
							:headers="headers"
							:items="connectors"
							:custom-filter="filterList"
							:search="search"
							item-value="uuid"
							class="elevation-1">
							<template #item.access="{ item }">
								<v-btn v-if="item.raw.status === 'RUNNING'" size="small" :href="item.raw.frontendUrl" target="_blank">
									View Frontend
								</v-btn>
							</template>
							<template #item.startedAt="{ item }">
								{{ mapOptional(formatDateTime, item.raw.startedAt) }}
							</template>
							<template #item.endedAt="{ item }">
								{{ mapOptional(getEndTime, item.raw) }}
							</template>
							<template #item.actions="{ item }">
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											class="mr-1"
											color="primary"
											icon="mdi-clipboard-text-search"
											size="x-small"
											v-bind="props"
											@click="viewLogs(item.raw)">
										</v-btn>
									</template>
									<span>View Logs</span>
								</v-tooltip>
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											class="mr-1"
											color="primary"
											icon="mdi-gauge"
											size="x-small"
											v-bind="props"
											@click="viewMetrics(item.raw)">
										</v-btn>
									</template>
									<span>View Metrics</span>
								</v-tooltip>
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											class="mr-1"
											color="primary"
											icon="mdi-shield-crown"
											size="x-small"
											v-bind="props"
											@click="viewPortalLogs(item.raw)">
										</v-btn>
									</template>
									<span>View Portal Logs</span>
								</v-tooltip>
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											v-if="auth.hasRole('sovity_admin')"
											:disabled="item.raw.status !== 'INIT' && item.raw.status !== 'STOPPED'"
											class="mr-1"
											color="success"
											icon="mdi-play"
											size="x-small"
											v-bind="props"
											@click="startConnector(item.raw)">
										</v-btn>
									</template>
									<span>Start Connector</span>
								</v-tooltip>
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											v-if="auth.hasRole('sovity_admin')"
											:disabled="item.raw.status !== 'RUNNING'"
											class="mr-1"
											color="error"
											icon="mdi-stop"
											size="x-small"
											v-bind="props"
											@click="stopConnector(item.raw)">
										</v-btn>
									</template>
									<span>Stop Connector</span>
								</v-tooltip>
								<v-tooltip location="top">
									<template #activator="{ props }">
										<v-btn
											v-if="auth.hasRole('sovity_admin')"
											:disabled="item.raw.status !== 'STOPPED' && item.raw.status !== 'ERROR'"
											class="mr-1"
											color="error"
											icon="mdi-delete"
											size="x-small"
											v-bind="props"
											@click="deleteConnector(item.raw)">
										</v-btn>
									</template>
									<span>Delete Connector</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</template>
			<div v-else class="d-flex justify-center">
				<v-progress-circular indeterminate></v-progress-circular>
			</div>
		</v-container>
	</app-page>
</template>
