<script setup lang="ts">
import { useSubscription } from '@/utils/composables'
import { routeInfo } from '@/router/router'
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { AppPage } from '@/lib'
import { useRoute } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { SovityConnectorApi } from '@/services/api/admin/connectorApi'
import { HttpError, NetworkError } from '@/services/api/common/errors'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import InfoTable from '@/views/components/InfoTable.vue'
import ConnectorInfoPanel from '@/views/components/ConnectorInfoPanel.vue'
import { DateTime } from 'luxon'

const auth = inject('auth') as KeycloakClient
const connectorApi = new SovityConnectorApi(auth)
const route = useRoute()
const pageError = ref<unknown>(null)
const showingError = ref(false)
const errorMessage = ref('')
const props = defineProps({
	connectorId: {
		type: String,
		required: true
	}
})
const title = computed(() => {
	return routeInfo.adminConnectorPortalLogs.title(connector.value?.name)
})
const crumbs = computed(() => {
	return [routeInfo.adminManagementConsole, { title: title.value, path: route.path }]
})
const { data, err, stopSubscription } = useSubscription(loadData)
function loadData() {
	return Promise.all([connectorApi.getConnector(props.connectorId), connectorApi.getConnectorPortalLogs(props.connectorId)])
}
const connector = computed(() => {
	return data.value?.[0] ?? null
})
const logs = computed(() => {
	return data.value?.[1] ?? null
})

watch(err, (newErr) => {
	if (newErr != null) {
		pageError.value = newErr
	}
})
watch(pageError, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof HttpError) {
		if (newErr.statusCode >= 500) {
			errorMessage.value = 'There was an error on our end'
		} else if (newErr.statusCode === 403) {
			errorMessage.value = `You don't have access to this resource: ${newErr.attemptedRequest.url}`
		}
	} else {
		errorMessage.value = `${newErr}`
	}
})

onUnmounted(stopSubscription)
</script>

<template>
	<app-page :title="title" :crumbs="crumbs">
		<error-popup v-model="showingError" :message="errorMessage"></error-popup>
		<div v-if="err != null">There was an error loading the connector information.</div>
		<div v-else-if="connector == null || logs == null" class="d-flex justify-center">
			<v-progress-circular indeterminate></v-progress-circular>
		</div>
		<template v-else>
			<connector-info-panel :connector="connector" :auth="auth"></connector-info-panel>
			<info-table
				:headers="['Timestamp', 'Message', 'Code']"
				:data="
					logs.map(({ creationDate, message, code }) => [
						DateTime.fromISO(creationDate).toLocaleString(DateTime.DATETIME_MED),
						message,
						code
					])
				"></info-table>
		</template>
	</app-page>
</template>
