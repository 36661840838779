import Keycloak from 'keycloak-js'
import { KeycloakInitError } from './errors'
import autoBind from 'auto-bind'
import type { AuthRole } from './roles'
import { join } from 'path-browserify'

export class KeycloakClient {
	private keycloak: Keycloak
	private roleClient: string | undefined = undefined

	constructor() {
		this.keycloak = new Keycloak('/keycloak.json')
		autoBind(this)
	}

	get Keycloak() {
		return this.keycloak
	}

	get bearerToken(): string | null {
		return (this.isLoggedIn() && this.keycloak.token) || null
	}

	get adminUiUrl(): string {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
		return new URL(join(this.Keycloak.authServerUrl, '/admin/master/console/#/', this.Keycloak.realm, '/users'))
			.href
	}

	async init() {
		await this.keycloak
			.init({
				onLoad: 'login-required',
				silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
			})
			.then((authenticated) => {
				console.debug(authenticated ? 'Keycloak: Authenticated' : 'Keycloak: Not authenticated')
			})
			.catch(() => {
				console.error('Keycloak: Failed to initialize')
				throw new KeycloakInitError()
			})

		const configResp = await fetch('/keycloak.json')
		if (!configResp.ok || !configResp.headers.get('Content-Type')?.includes('application/json')) {
			throw new KeycloakInitError()
		}

		const { 'role-client': roleClient } = await configResp.json()
		this.roleClient = roleClient || undefined
	}

	async refreshTokenIfAboutToExpire(): Promise<void> {
		await this.keycloak.updateToken(70)
	}

	isLoggedIn(): boolean {
		return this.keycloak.authenticated ?? false
	}

	hasRole(role: AuthRole): boolean {
		return this.keycloak.hasResourceRole(role, this.roleClient)
	}

	async login(): Promise<void> {
		await this.keycloak.login()
	}

	async logout(): Promise<void> {
		await this.keycloak.logout()
	}
}
