import {DateTime} from 'luxon'

export function formatDateTime(value: DateTime | undefined): string | null {
	if (value != null) {
		return value.toLocaleString(DateTime.DATETIME_SHORT)
	} else {
		return null
	}
}

export function roundFixed(x: number, placesAfterDecimal: number): number {
	const scale = 10 ** placesAfterDecimal
	return Math.round((x + Number.EPSILON) * scale) / scale
}
