import { createRouter, createWebHistory, type Router } from 'vue-router'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import HomePage from '@/views/pages/HomePage.vue'
import CustomerConnectorsPage from '@/views/pages/user/ManagementConsolePage.vue'
import UserConnectorLogPage from '@/views/pages/user/ConnectorLogPage.vue'
import AdminManagementConsolePage from '@/views/pages/admin/ManagementConsolePage.vue'
import NewConnectorPage from '@/views/pages/admin/AddConnectorPage.vue'
import SettingsPage from '@/views/pages/admin/SettingsPage.vue'
import AdminConnectorLogPage from '@/views/pages/admin/ConnectorLogPage.vue'
import NotFoundPage from '@/views/pages/error/NotFoundErrorPage.vue'
import ConnectorPortalLogPage from '@/views/pages/admin/ConnectorPortalLogPage.vue'
import ConnectorMetricsPage from '@/views/pages/user/ConnectorMetricsPage.vue'
import AdminConnectorMetricsPage from '@/views/pages/admin/ConnectorMetricsPage.vue'

type DynamicField = (x: string) => string
type DynamicFieldWithFallback = (x: string | null | undefined) => string
type StaticField = string
type Field = DynamicField | StaticField

export interface RouteData<Title extends Field = StaticField, Path extends Field = StaticField> {
	title: Title
	path: Path
}

export const routeInfo = {
	home: { title: 'Home', path: '/' } as RouteData,
	customerConnectors: {
		title: (orgName: string | null | undefined) => `${orgName ?? 'Your Company'}'s Connectors`,
		path: '/customer/connectors'
	} as RouteData<DynamicFieldWithFallback, StaticField>,
	customerConnectorLogs: {
		title: (connectorName: string | null | undefined) => `Connector ${connectorName} Logs`,
		path: (connectorId: string) => `/customer/connectors/${connectorId}/logs`
	} as RouteData<DynamicFieldWithFallback, DynamicField>,
	customerConnectorMetrics: {
		title: (connectorName: string | null | undefined) => `Connector ${connectorName} Metrics`,
		path: (connectorId: string) => `/customer/connectors/${connectorId}/metrics`
	} as RouteData<DynamicFieldWithFallback, DynamicField>,
	adminManagementConsole: { title: 'Admin Management Console', path: '/admin/connector-management' } as RouteData,
	adminSettings: { title: 'Portal Settings', path: '/admin/settings' } as RouteData,
	adminNewConnector: { title: 'Create New Connector', path: '/admin/connectors/new' } as RouteData,
	adminConnectorLogs: {
		title: (connectorName: string | null | undefined) => `Connector ${connectorName}'s Logs`,
		path: (connectorId: string) => `/admin/connectors/${connectorId}/logs`
	} as RouteData<DynamicFieldWithFallback, DynamicField>,
	adminConnectorMetrics: {
		title: (connectorName: string | null | undefined) => `Connector ${connectorName} Metrics`,
		path: (connectorId: string) => `/admin/connectors/${connectorId}/metrics`
	} as RouteData<DynamicFieldWithFallback, DynamicField>,
	adminConnectorPortalLogs: {
		title: (connectorName: string | null | undefined) => `Connector ${connectorName}'s Portal Logs`,
		path: (connectorId: string) => `/admin/connectors/${connectorId}/portal-logs`
	} as RouteData<DynamicFieldWithFallback, DynamicField>,
	networkError: { title: 'Network Error', path: '/network-error' } as RouteData
}

export function buildRouter(auth: KeycloakClient): Router {
	const router = createRouter({
		history: createWebHistory(import.meta.env.BASE_URL),
		routes: [
			{
				name: 'Home',
				path: routeInfo.home.path,
				components: { default: HomePage }
			},
			{
				name: 'Customer.Connectors',
				path: routeInfo.customerConnectors.path,
				components: {
					default: CustomerConnectorsPage
				}
			},
			{
				name: 'Customer.Connector.Logs',
				path: routeInfo.customerConnectorLogs.path(':connectorId'),
				components: {
					default: UserConnectorLogPage
				},
				props: { default: true }
			},
			{
				name: 'Customer.Connector.Metrics',
				path: routeInfo.customerConnectorMetrics.path(':connectorId'),
				components: {
					default: ConnectorMetricsPage
				},
				props: { default: true }
			},
			{
				name: 'Admin.Management',
				path: routeInfo.adminManagementConsole.path,
				components: {
					default: AdminManagementConsolePage
				}
			},
			{
				name: 'Admin.Connector.New',
				path: routeInfo.adminNewConnector.path,
				components: {
					default: NewConnectorPage
				}
			},
			{
				name: 'Admin.Connector.Logs',
				path: routeInfo.adminConnectorLogs.path(':connectorId'),
				components: {
					default: AdminConnectorLogPage
				},
				props: { default: true }
			},
			{
				name: 'Admin.Connector.Metrics',
				path: routeInfo.adminConnectorMetrics.path(':connectorId'),
				components: {
					default: AdminConnectorMetricsPage
				},
				props: { default: true }
			},
			{
				name: 'Admin.Connector.PortalLogs',
				path: routeInfo.adminConnectorPortalLogs.path(':connectorId'),
				components: {
					default: ConnectorPortalLogPage
				},
				props: { default: true }
			},
			{
				name: 'Admin.Settings',
				path: routeInfo.adminSettings.path,
				components: {
					default: SettingsPage
				},
				props: { default: true }
			},
			{
				name: 'Error.NotFound',
				path: '/:attemptedPath(.*)*',
				components: {
					default: NotFoundPage
				},
				props: { default: true }
			}
		]
	})

	router.beforeEach(async (to) => {
		const startsWithAdmin = /^\/admin/
		const startsWithCustomer = /^\/customer/
		const isCustomerLogsPage = /^\/customer\/connectors\/([a-z0-9]-)+[a-z0-9]\/logs/
		if (
			(!auth.hasRole('sovity_user') && startsWithAdmin.test(to.path)) ||
			(!auth.hasRole('customer_admin') && isCustomerLogsPage.test(to.path)) ||
			(!auth.hasRole('customer_user') && startsWithCustomer.test(to.path))
		) {
			await router.push(routeInfo.home.path)
		}
	})

	return router
}
