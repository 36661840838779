import { ref, type Ref } from 'vue'

export function usePromise<T>(promise: Promise<T>): { result: Ref<T | null>; err: Ref<unknown | null> } {
	const result = ref(null) as Ref<T | null>
	const err = ref(null) as Ref<unknown | null>

	promise
		.then((x) => {
			result.value = x
		})
		.catch((e) => {
			err.value = e
		})

	return { result, err }
}

export function useSubscription<T>(
	fetcher: () => Promise<T>,
	refreshMilis = 5_000
): {
	data: Ref<T | null>
	err: Ref<unknown | null>
	stopSubscription: () => void
	fetchNow: () => void
} {
	const data = ref(null) as Ref<T | null>
	const err = ref<null | unknown>(null)

	async function getData() {
		try {
			data.value = await fetcher()
			err.value = null
		} catch (e) {
			err.value = e
		}
	}
	void getData()
	const subId = setInterval(getData, refreshMilis)
	function stopSubscription() {
		clearInterval(subId)
	}
	function fetchNow() {
		void getData()
	}
	return { data, err, stopSubscription, fetchNow }
}
