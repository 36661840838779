<script lang="ts" setup>
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { BaseLayout } from '@/lib'
import { computed, inject, ref } from 'vue'
import { routeInfo } from '@/router/router'
import ErrorPopup from './views/components/ErrorPopup.vue'
import SuccessPopup from './views/components/SuccessPopup.vue'
import { useUserStore } from './stores/userStore'

const auth = inject('auth') as KeycloakClient
const userStore = useUserStore()
userStore.loadUserIfNecessary()

const userTitle = computed(() => {
	if (userStore.user?.firstName != null && userStore.user?.lastName != null) {
		return userStore.user.firstName + ' ' + userStore.user.lastName
	} else {
		return userStore.user?.username
	}
})

const showingError = ref(false)
const errorMessage = ref('')
</script>

<template>
	<base-layout>
		<template #top-left>
			<div v-if="auth.isLoggedIn()">
				<span>Hi {{ userTitle }}!</span>
				<v-divider inset vertical></v-divider>
				<v-menu open-on-hover>
					<template #activator="{ props }">
						<v-btn color="primary" v-bind="props">
							<v-icon>mdi-account</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item
							:href="auth.Keycloak.authServerUrl + '/realms/' + auth.Keycloak.realm + '/account/#/personal-info'"
							target="_blank">
							<v-list-item-title>Manage Password</v-list-item-title>
						</v-list-item>
						<v-list-item @click="auth.logout()">
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			<div v-else>
				<span>Not authenticated</span>
				<v-btn text @click="auth.login()"> Login</v-btn>
			</div>
		</template>
		<template #nav-items>
			<v-list-item :to="routeInfo.home.path" prepend-icon="mdi-home" color="primary" title="Home" active-class="bg-active">
			</v-list-item>
			<v-list-item
				v-if="auth.hasRole('customer_user') && routeInfo.customerConnectors.title(userStore.user?.customerOrganizationId) != null"
				:to="routeInfo.customerConnectors.path"
				prepend-icon="mdi-connection"
				color="primary"
				title="Connectors"
				active-class="bg-active">
			</v-list-item>
			<v-list-item
				v-if="auth.hasRole('sovity_user')"
				:to="routeInfo.adminManagementConsole.path"
				prepend-icon="mdi-shield-crown"
				color="primary"
				title="Administration"
				active-class="bg-active">
			</v-list-item>
			<v-list-item
				v-if="auth.hasRole('sovity_user')"
				:to="routeInfo.adminSettings.path"
				prepend-icon="mdi-cog"
				color="primary"
				title="Portal Settings"
				active-class="bg-active">
			</v-list-item>
			<v-list-item
				v-if="auth.hasRole('sovity_admin')"
				:href="auth.adminUiUrl"
				target="_blank"
				prepend-icon="mdi-account-group"
				color="primary"
				title="Go to Keycloak Admin UI"
				active-class="bg-active">
			</v-list-item>
		</template>
		<template #default>
			<error-popup v-model="showingError" :message="errorMessage"></error-popup>
			<success-popup v-model="showingError" :message="errorMessage"></success-popup>
			<router-view></router-view>
		</template>
	</base-layout>
</template>
<style lang="sass">
.bg-active
	background-color: #EBF4FF
</style>
