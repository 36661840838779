import { BaseApi } from '@/services/api/common/baseApi'
import type { Connector, RawConnector } from '@/models/entities/connector/Connector'
import * as connectorHelpers from '@/models/entities/connector/helpers'
import type { ProductPackageType } from '@/models/entities/connector/ProductPackageType'
import type { User } from '@/models/entities/User'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import autoBind from 'auto-bind'
import type { PortalMessage } from '@/models/messages/PortalMessage'
import { unwrapOptional } from '@/utils/functionalHelpers'
import { CustomerConnectorApi } from '../user/connectorApi'
import type { InternalLogEntity } from '@/models/entities/internal_log_entity/InternalLogEntity'
import type { ConnectorType } from '@/models/entities/connector/ConnectorType'
import type { ConnectorImageDescriptionDto } from '@/models/entities/connector/ConnectorImageDescriptionDto'
import { NetworkError } from '@/services/api/common/errors'

export class SovityConnectorApi extends CustomerConnectorApi {
	static readonly BASE_URL = BaseApi.BASE_URL + '/admin/connectors'

	constructor(auth: KeycloakClient) {
		super(auth)
		this.baseUrl = SovityConnectorApi.BASE_URL
		autoBind(this)
	}

	async getConnectorPortalLogs(connectorId: string) {
		const { value } = (await this.get(`/${connectorId}/portal-logs`)) as PortalMessage<InternalLogEntity[]>
		return unwrapOptional(value)
	}

	async generateConnectorName(): Promise<string> {
		const { value } = (await this.get('/generation/name')) as PortalMessage<string>
		return unwrapOptional(value)
	}

	async validateConnectorName(name: string): Promise<boolean> {
		const { value } = (await this.get('/validation/name', { name })) as PortalMessage<boolean>
		return unwrapOptional(value)
	}

	async startConnector(connectorId: string): Promise<void> {
		await this.post(`/${connectorId}/management/start`)
	}

	async stopConnector(connectorId: string): Promise<void> {
		await this.post(`/${connectorId}/management/stop`)
	}

	async deleteConnector(connectorId: string): Promise<void> {
		await this.delete(`/${connectorId}`)
	}

	async createConnector(
		name: string,
		connectorType: ConnectorType,
		description: string,
		plannedRuntimeAsDuration: string,
		productPackageType: ProductPackageType,
		connectorIamEntityUuid: string,
		userIamEntityUuid: string,
		curatorUri: string,
		curator: User,
		customerOrganizationId: string,
		customerOrganizationLegalName: string,
		imageDescriptions: Array<ConnectorImageDescriptionDto>
	): Promise<Connector> {
		const { value } = (await this.post('/new', {
			name,
			connectorType,
			description,
			plannedRuntimeAsDuration,
			productPackageType,
			connectorIamEntityUuid,
			userIamEntityUuid,
			curatorUri,
			curator,
			customerOrganizationId,
			customerOrganizationLegalName,
			imageDescriptions
		}).catch((e) => {
			console.error(e)
			throw new NetworkError(e)
		})) as PortalMessage<RawConnector>
		return connectorHelpers.parseConnector(unwrapOptional(value))
	}
}
