<script setup lang="ts">
import { AppPage } from '@/lib'
import { routeInfo } from '@/router/router'
import { usePromise } from '@/utils/composables'
import { computed, inject, isRef, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { helpers, maxLength, minLength, required, requiredIf } from '@vuelidate/validators'
import { type ErrorObject, useVuelidate } from '@vuelidate/core'
import type { Connector } from '@/models/entities/connector/Connector'
import { useRouter } from 'vue-router'
import { valueList } from "@/models/entities/connector/ProductPackageType";
import type { ProductPackageType } from '@/models/entities/connector/ProductPackageType'
import type { KeycloakClient } from '@/services/keycloak/keycloak'
import { SovityConnectorApi } from '@/services/api/admin/connectorApi'
import { CuratorApi } from '@/services/api/admin/curatorApi'
import { HttpError, NetworkError } from '@/services/api/common/errors'
import ErrorPopup from '@/views/components/ErrorPopup.vue'
import { useUserStore } from '@/stores/userStore'
import { type ConnectorType, valueList as connectorTypes } from '@/models/entities/connector/ConnectorType'
import { ConnectorImageDescriptionDto } from '@/models/entities/connector/ConnectorImageDescriptionDto'
import { PortalApi } from '@/services/api/admin/portalApi'
import type { UserIamEntity } from '@/models/entities/connector/ConnectorUserIamEntity'
import type { ConnectorIamEntity } from '@/models/entities/connector/ConnectorIamEntity'
import type { RepositoryImageEntity } from '@/models/entities/connector/ConnectorRepositoryImageEntity'

const auth = inject('auth') as KeycloakClient
const connectorApi = new SovityConnectorApi(auth)
const curatorApi = new CuratorApi(auth)
const userStore = useUserStore()
const curatorIdmId = ref<string | null>(null)
userStore.loadUserIfNecessary().then(() => {
	curatorIdmId.value = userStore.user?.idmId ?? null
})

const router = useRouter()
const edcFrontendImages = ref<RepositoryImageEntity[] | null>(null)
const edcFrontendImage = ref<string | null>(null)
const edcControlPlaneImages = ref<RepositoryImageEntity[] | null>(null)
const edcControlPlaneImage = ref<string | null>(null)
const edcDataPlaneImages = ref<RepositoryImageEntity[] | null>(null)
const edcDataPlaneImage = ref<string | null>(null)
const crumbs = [routeInfo.adminManagementConsole, routeInfo.adminNewConnector]
const { result: savedCurators, err: fetchCuratorsErr } = usePromise(curatorApi.getCurators())
const submissionError = ref<unknown>(null)
const pageError = computed(() => {
	return fetchFrontendImagesErr ?? fetchBackendImagesErr ?? fetchCuratorsErr ?? submissionError
})

// Form values
const { result: name } = usePromise(connectorApi.generateConnectorName())
const description = ref('')
const plannedRuntimeAsDuration = ref('P1Y')
const productPackageType = ref<ProductPackageType>('BASIC')
const customerOrganizationId = ref('sovity')
const customerOrganizationLegalName = ref('sovity GmbH')
const curatorUri = ref('https://sovity.de')
const connectorType = ref<ConnectorType>('EDC')
const connectorIamList: ConnectorIamEntity[] = []
const connectorIam = ref()
const iamList: UserIamEntity[] = []
const iam = ref()
let imageList: ConnectorImageDescriptionDto[] = []
const portalApi = new PortalApi(auth)
const deploymentSettings = usePromise(portalApi.getDeploymentSettings()).result
let deploymentSettingsData: {
	userIamList: UserIamEntity[]
	connectorIamList: ConnectorIamEntity[]
	repositoryImageList: RepositoryImageEntity[]
}
// Load available images dependent on the connector type
const fetchFrontendImagesErr = ref(null) as Ref<unknown | null>
const fetchBackendImagesErr = ref(null) as Ref<unknown | null>
const connectorEdcFrontendImageList: RepositoryImageEntity[] = []
const connectorEdcControlPlaneImageList: RepositoryImageEntity[] = []
const connectorEdcDataPlaneImageList: RepositoryImageEntity[] = []
const edcFrontendImageEnv = ref('{}')
const edcControlPlaneImageEnv = ref('{}')
const edcDataPlaneImageEnv = ref('{}')
const buttonKey = ref(1)

watch(deploymentSettings, (data: string | null) => {
	if (data != null) {
		deploymentSettingsData = JSON.parse(data)
		deploymentSettingsData.connectorIamList.forEach((value: ConnectorIamEntity) => {
			value.title = value.name + ' (' + value.connectorIamType + ')'
			connectorIamList.push(value)
		})

		deploymentSettingsData.userIamList.forEach((value: UserIamEntity) => {
			value.title = value.name + ' (' + value.iamType + ')'
			iamList.push(value)
		})

		deploymentSettingsData.repositoryImageList.forEach((value: RepositoryImageEntity) => {
			if (value.repositoryImageType === 'EDC_UI') connectorEdcFrontendImageList.push(value)
			else if (value.repositoryImageType === 'EDC_DATA_PLANE') connectorEdcDataPlaneImageList.push(value)
			else if (value.repositoryImageType === 'EDC_CONTROL_PLANE') connectorEdcControlPlaneImageList.push(value)
		})

		edcFrontendImages.value = connectorEdcFrontendImageList
		edcControlPlaneImages.value = connectorEdcControlPlaneImageList
		edcDataPlaneImages.value = connectorEdcDataPlaneImageList

		if (connectorIamList.length > 0) {
			connectorIam.value = connectorIamList[0].uuid
		}
		if (iamList.length > 0) {
			iam.value = iamList[0].uuid
		}
		if (connectorEdcFrontendImageList.length > 0) {
			edcFrontendImage.value = connectorEdcFrontendImageList[0]?.uuid ?? ''
			edcFrontendImageEnv.value = JSON.stringify(connectorEdcFrontendImageList[0].environmentVariables, null, 4)
		}
		if (connectorEdcControlPlaneImageList.length > 0) {
			edcControlPlaneImage.value = connectorEdcControlPlaneImageList[0]?.uuid ?? ''
			edcControlPlaneImageEnv.value = JSON.stringify(connectorEdcControlPlaneImageList[0].environmentVariables, null, 4)
		}
		if (connectorEdcDataPlaneImageList.length > 0) {
			edcDataPlaneImage.value = connectorEdcDataPlaneImageList[0]?.uuid ?? ''
			edcDataPlaneImageEnv.value = JSON.stringify(connectorEdcDataPlaneImageList[0].environmentVariables, null, 4)
		}
	}
})

const curatorMap = computed(() => {
	const m = new Map((savedCurators.value ?? []).map((c) => [c.idmId, c]))
	if (userStore.user) {
		m.set(userStore.user.idmId, userStore.user)
	}
	return m
})

function updateEnvironmentVariablesFieldOnImageSelect(imageUuid: string) {
	//Find the image which was selected
	const item = deploymentSettingsData.repositoryImageList.find((image) => {
		return image.uuid === imageUuid
	})

	if (item !== undefined) {
		const environmentVariables = JSON.stringify(item.environmentVariables, null, 4)
		//Identify which env field to update
		if (item.repositoryImageType === 'EDC_UI') edcFrontendImageEnv.value = environmentVariables
		else if (item.repositoryImageType === 'EDC_DATA_PLANE') edcDataPlaneImageEnv.value = environmentVariables
		else if (item.repositoryImageType === 'EDC_CONTROL_PLANE') edcControlPlaneImageEnv.value = environmentVariables
	}
}

// Form validation
const rules = {
	name: {
		required,
		limitCharacters: helpers.withMessage(
			'Connector name can only contain letters, numbers and dashes (e.g. my-connector).',
			helpers.regex(/^[a-zA-Z0-9]+([-][a-zA-Z0-9]+)*$/)
		),
		isUnique: helpers.withMessage('Connector name must be unique', helpers.withAsync(connectorApi.validateConnectorName)),
		minLength: minLength(6),
		maxLength: maxLength(23),
		$autoDirty: true
	},
	connectorType: { required },
	edcFrontendImage: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		$autoDirty: true
	},
	edcControlPlaneImage: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		$autoDirty: true
	},
	edcDataPlaneImage: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		$autoDirty: true
	},
	edcFrontendImageEnv: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		isJSON:
			connectorType.value === 'EDC'
				? helpers.withMessage('Environment value must be JSON', () => typeof JSON.parse(edcFrontendImageEnv.value) === 'object')
				: true,
		$autoDirty: true
	},
	edcControlPlaneImageEnv: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		isJSON:
			connectorType.value === 'EDC'
				? helpers.withMessage('Environment value must be JSON', () => typeof JSON.parse(edcControlPlaneImageEnv.value) === 'object')
				: true,
		$autoDirty: true
	},
	edcDataPlaneImageEnv: {
		required: requiredIf(() => {
			return connectorType.value === 'EDC'
		}),
		isJSON:
			connectorType.value === 'EDC'
				? helpers.withMessage('Environment value must be JSON', () => typeof JSON.parse(edcDataPlaneImageEnv.value) === 'object')
				: true,
		$autoDirty: true
	},
	// ISO 8601 time format (e.g. P365DT3H4M), can contain days, hours and minutes
	plannedRuntimeAsDuration: {
		required,
		ISOformat: helpers.withMessage(
			"Connector's duration must be in ISO 8601 time format (e.g. P1Y2M3DT4H5M)",
			helpers.regex(/^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/)
		),
		$autoDirty: true
	},
	description: { $autoDirty: true },
	customerOrganizationId: { required, $autoDirty: true },
	customerOrganizationLegalName: { required, $autoDirty: true }
}

const vuelidate = useVuelidate(
	rules,
	{
		name,
		connectorType,
		edcFrontendImage,
		edcControlPlaneImage,
		edcDataPlaneImage,
		edcFrontendImageEnv,
		edcControlPlaneImageEnv,
		edcDataPlaneImageEnv,
		plannedRuntimeAsDuration,
		description,
		customerOrganizationId,
		customerOrganizationLegalName
	},
	{ $lazy: true }
)

function mapVuelidateErrors({ $errors }: { $errors: ErrorObject[] }) {
	return $errors.map(({ $message }: ErrorObject) => {
		return isRef($message) ? $message.value : $message
	})
}

async function validateAndCreate(): Promise<Connector | null> {
	const curator = curatorIdmId.value != null ? curatorMap.value.get(curatorIdmId.value) : null
	if (
		(await vuelidate.value.$validate()) &&
		userStore.user != null &&
		curator != null &&
		name.value != null &&
		curatorUri.value != null
	) {
		imageList = []
		if (connectorType.value === 'EDC') {
			imageList.push(new ConnectorImageDescriptionDto(edcFrontendImage.value || '', edcFrontendImageEnv.value))
			imageList.push(new ConnectorImageDescriptionDto(edcDataPlaneImage.value || '', edcDataPlaneImageEnv.value))
			imageList.push(new ConnectorImageDescriptionDto(edcControlPlaneImage.value || '', edcControlPlaneImageEnv.value))
		}
		return connectorApi.createConnector(
			name.value,
			connectorType.value,
			description.value,
			plannedRuntimeAsDuration.value,
			productPackageType.value,
			connectorIam.value,
			iam.value,
			curatorUri.value,
			curator,
			customerOrganizationId.value,
			customerOrganizationLegalName.value,
			imageList
		)
	} else {
		errorMessage.value = ''
		vuelidate.value.$errors.forEach((e) => (errorMessage.value += 'Error on field ' + e['$property'] + ': ' + e['$message'] + '. '))
		showingError.value = true
		buttonKey.value += 1
		return null
	}
}

async function submit() {
	try {
		const connector = await validateAndCreate()
		if (connector != null) {
			await connectorApi.startConnector(connector.uuid)
			await router.push(routeInfo.adminManagementConsole.path)
		}
	} catch (err) {
		submissionError.value = err
	}
}

const showingError = ref(false)
const errorMessage = ref('')
watch(pageError, (newErr) => {
	showingError.value = newErr != null
	if (newErr instanceof NetworkError) {
		errorMessage.value = "We can't connect to sovity servers."
	} else if (newErr instanceof HttpError) {
		if (newErr.statusCode >= 500) {
			errorMessage.value = 'There was an error on our end'
		} else if (newErr.statusCode === 403) {
			errorMessage.value = `You don't have access to this resource: ${newErr.attemptedRequest.url}`
		}
	} else {
		errorMessage.value = `${newErr}`
	}
})

watch(vuelidate.value.customerOrganizationId, () => {
	if (vuelidate.value.customerOrganizationId.$dirty) {
		customerOrganizationLegalName.value = ''
	}
	if (customerOrganizationId.value === 'sovity') {
		customerOrganizationLegalName.value = 'sovity GmbH'
	}
})
</script>

<template>
	<app-page :title="routeInfo.adminNewConnector.title" :crumbs="crumbs">
		<error-popup v-model="showingError" :message="errorMessage"></error-popup>
		<v-form>
			<v-container class="pa-0">
				<v-row>
					<v-col cols="12" md="4">
						<div v-if="name == null">
							<v-progress-circular indeterminate></v-progress-circular>
						</div>
						<v-text-field
							v-else
							v-model="name"
							required
							label="Name"
							:error-messages="mapVuelidateErrors(vuelidate.name)"
							variant="outlined"
							color="blue"></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-select
							v-model="connectorType"
							:items="connectorTypes"
							label="Connector Type"
							:error-messages="mapVuelidateErrors(vuelidate.connectorType)"
							variant="outlined"
							color="blue"></v-select>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<div v-if="edcFrontendImages == null || edcFrontendImage == null">
							<v-progress-circular indeterminate></v-progress-circular>
						</div>
						<v-select
							v-else
							v-model="edcFrontendImage"
							:items="edcFrontendImages"
							item-title="name"
							item-value="uuid"
							label="Frontend Image"
							variant="outlined"
							color="blue"
							@update:model-value="updateEnvironmentVariablesFieldOnImageSelect(edcFrontendImage)"></v-select>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<v-textarea
							v-if="edcFrontendImage != null && connectorType === 'EDC'"
							v-model="edcFrontendImageEnv"
							label="Frontend Image - Environment value"
							:error-messages="mapVuelidateErrors(vuelidate.edcFrontendImageEnv)"
							variant="outlined"
							color="blue"></v-textarea>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<div v-if="edcControlPlaneImages == null || edcControlPlaneImage == null">
							<v-progress-circular indeterminate></v-progress-circular>
						</div>
						<v-select
							v-else
							v-model="edcControlPlaneImage"
							:items="edcControlPlaneImages"
							item-title="name"
							item-value="uuid"
							label="Control Plane Image"
							variant="outlined"
							color="blue"
							@update:model-value="updateEnvironmentVariablesFieldOnImageSelect(edcControlPlaneImage)"></v-select>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<v-textarea
							v-if="edcControlPlaneImage != null && connectorType === 'EDC'"
							v-model="edcControlPlaneImageEnv"
							:error-messages="mapVuelidateErrors(vuelidate.edcControlPlaneImageEnv)"
							label="Control Plane Image - Environment value"
							variant="outlined"
							color="blue"></v-textarea>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<div v-if="edcDataPlaneImages == null || edcDataPlaneImage == null">
							<v-progress-circular indeterminate></v-progress-circular>
						</div>
						<v-select
							v-else
							v-model="edcDataPlaneImage"
							:items="edcDataPlaneImages"
							item-title="name"
							item-value="uuid"
							label="Data Plane Image"
							variant="outlined"
							color="blue"
							@update:model-value="updateEnvironmentVariablesFieldOnImageSelect(edcDataPlaneImage)"></v-select>
					</v-col>
				</v-row>
				<v-row v-if="connectorType === 'EDC'">
					<v-col cols="12" md="8">
						<v-textarea
							v-if="edcDataPlaneImage != null && connectorType === 'EDC'"
							v-model="edcDataPlaneImageEnv"
							label="Data Plane Image - Environment value"
							:error-messages="mapVuelidateErrors(vuelidate.edcDataPlaneImageEnv)"
							variant="outlined"
							color="blue"></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4">
						<v-select
							v-model="productPackageType"
							:items="valueList"
							label="Product Package Type"
							variant="outlined"
							color="blue"></v-select>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							v-model="plannedRuntimeAsDuration"
							label="Planned runtime in ISO format"
							required
							:error-messages="mapVuelidateErrors(vuelidate.plannedRuntimeAsDuration)"
							variant="outlined"
							color="blue"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4">
						<v-select
							v-model="connectorIam"
							:items="connectorIamList"
							item-title="title"
							item-value="uuid"
							label="Connector IAM"
							variant="outlined"
							color="blue"></v-select>
					</v-col>
					<v-col cols="12" md="4">
						<v-select
							v-model="iam"
							:items="iamList"
							item-title="title"
							item-value="uuid"
							label="User Management"
							variant="outlined"
							color="blue"></v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="8">
						<v-textarea
							v-model="description"
							label="Description"
							:error-messages="mapVuelidateErrors(vuelidate.description)"
							variant="outlined"
							color="blue"></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4">
						<v-text-field
							v-model="customerOrganizationId"
							required
							label="Customer Organization ID"
							:error-messages="mapVuelidateErrors(vuelidate.customerOrganizationId)"
							variant="outlined"
							color="blue"></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							v-model="customerOrganizationLegalName"
							required
							label="Customer Organization Name"
							:error-messages="mapVuelidateErrors(vuelidate.customerOrganizationLegalName)"
							variant="outlined"
							color="blue"></v-text-field>
						<div v-if="vuelidate.customerOrganizationId.$dirty"></div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="4">
						<v-btn :key="buttonKey" class="mr-4" color="primary" @click.once="submit()">Start</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</app-page>
</template>
